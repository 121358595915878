
import { defineComponent, reactive, toRefs, computed } from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";

export default defineComponent({
  name: "PluginAHCRMUser",
  components: {},
  setup() {
    let data = reactive<any>({
      AHUsers: [],
      formInline: {
        phone: "",
      },
    });

    const onSearch = () => {
      if (data.formInline.phone.length != 11) {
        ElMessage.warning({
          showClose: true,
          duration: 10000,
          message: "请输入手机号码",
        });
        return;
      }
      data.AHUsers=[];
      console.info(data.formInline);
      Api.Api2.AHUser(data.formInline).then((res: any) => {
        console.log(res.data);
        if (res.code == "0") {
          data.AHUsers = res.data;
          console.log(data.AHUsers);
        } else {
          ElMessage.warning(res.resultMsg);
        }
      });
    };

    const unsubscribe = (row: any) => {
      ElMessageBox.confirm("确定要取消该用户吗？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "",
      })
        .then(() => {
          const dto = {
            phone: row.userphone,
            productCode: row.productCode,
          };
          Api.Api2.AHunsubscribe(dto).then((res: any) => {
            if (res.code == "0") {
              ElMessage.success("取消成功");
              onSearch();
            } else {
              ElMessage.warning({
                showClose: true,
                duration: 10000,
                message: res.resultMsg,
              });
            }
          });
        })
        .catch(() => {
          console.log("xx");
        });
    };

    const UserStatus = computed(() => {
      return function (text: string) {
        if (text == "0") {
          return "未订购";
        } else if (text == "1") {
          return "订购";
        } else if (text == "2") {
          return "退订";
        }
      };
    });

    const UserOrderStatus = computed(() => {
      return function (text: string) {
        if (text == "A") {
          return "订购";
        } else if (text == "D") {
          return "退订";
        } else if (text == "F") {
          return "未知";
        }
      };
    });

    return {
      ...toRefs(data),
      onSearch,
      UserStatus,
      unsubscribe,
      UserOrderStatus,
    };
  },
});
